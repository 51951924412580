import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { share } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../@core/alert.service';
import { ParceriaInfo } from '../modulo2/detalheParceria/parceriaInfo';
import { DetalheParceriaApi } from '../apis/detalheParceriaApi';
import { Mascaras } from '../@shared/components/Mascaras';
import { UserService } from '../@core/user.service';
import { PrestadorServTer } from './prestadorServTer';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PessoaInfo } from "../modulo2/detalheParceria/pessoaInfo";
import { LogonApi } from 'src/app/apis/logonApi';
import { AuthUser, KeycloakService } from '@procempa/ngx-keycloak';
import { PublicoPrivadaApi } from 'src/app/apis/publicoPrivadaApi';
import { DateConverter } from 'src/app/@core/date-converter';
import { BuscaRelatorioCampos } from 'src/app/relatorios/buscaRelatorioCampos';
import { RelatoriosApi } from 'src/app/apis/relatoriosApi';
import { Utils } from 'src/app/@shared/utils';
import { CargaHorariaServTer } from './cargaHorariaServTer';
import { DetalheParceriaPortalApi } from '../apis/detalheParceriaPortalApi';
import { ExportAsConfig } from 'ngx-export-as';
import { OcupacaoLaboral } from './oupacaoLaboral';
import { ServicoPrestadoPf } from './servicoPrestadoPF';
import { ContratosPj } from './contratosPJ';
import { DatePipe } from '@angular/common';


@Component({
    selector: 'app-prestadorServTer',
    templateUrl: 'prestadorServTer.component.html',
    styleUrls: ['./../modulo2/detalheParceria/detalheParceria.component.scss']
})
export class PrestadorServTerComponent implements OnInit {
    @Input()
    private parceriaInfo: ParceriaInfo;
    @Input()
    private isPortal: boolean;
    @Output() valueChange = new EventEmitter();
    prestadores: Array<PrestadorServTer> = [];
    private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public loading$ = this.loading.asObservable().pipe(share());
    private temPermissao: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public temPermissao$ = this.temPermissao.asObservable().pipe(share());
    private naoTemPermissao: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public naoTemPermissao$ = this.naoTemPermissao.asObservable().pipe(share());
    mascaraNroHora = { mask: [/\d/, /\d/], guide: false };
    modalEdicaoReference: any;
    showUpload: boolean = false;
    prestadorSend: PrestadorServTer;
    isFiscal: boolean;
    isSMTC: boolean;
    isConsulta: boolean;
    inValidCpf: boolean = false;
    mascaraCPF = { mask: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/], guide: false };
    mascaraCNPJ = { mask: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/], guide: false };
    mascaraCEP = { mask: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/], guide: false };
    mascaraNroMat = { mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/], guide: false };
    mascaraNroDoc = { mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/], guide: false };
    pessoaInfo: PessoaInfo = new PessoaInfo();
    cnpjs: Array<Array<String>> = [];
    cnpjOSC: string;
    nomeOsc: string;
    cpf: string;
    termos: Array<Array<String>> = [];
    isCPF: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isCPF$ = this.isCPF.asObservable().pipe(share());

    isCNPJ: BehaviorSubject<boolean> = new BehaviorSubject(true);
    isCNPJ$ = this.isCNPJ.asObservable().pipe(share());

    isNotSelect: BehaviorSubject<boolean> = new BehaviorSubject(true);
    isNotSelect$ = this.isNotSelect.asObservable().pipe(share());
    username: string;

    isEdicao: boolean;
    ocupacaoLaboralLista: Array<OcupacaoLaboral> = [];
    tipoDeServico: any;
    ocupacaoLaboralSelecionada: any;
    isSaude: boolean;
    isBloquearEdicaoEndereco: boolean;
    servicoPrestadoSelecionado = new ServicoPrestadoPf();
    listaServicoPrestadoOption = [];
    servicoPrestadoSel: any;
    private noServicoId: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public noServicoId$ = this.noServicoId.asObservable().pipe(share());

    @ViewChildren('test') pdfTable: QueryList<any>;
    config: ExportAsConfig = {
        type: 'pdf', // the type you want to download
        elementIdOrContent: 'content', // the id of html/table element
    }


    constructor(private route: ActivatedRoute,
        private alertService: AlertService,
        private detalheParceriaApi: DetalheParceriaApi,
        private detalheParceriaPortalApi: DetalheParceriaPortalApi,
        public userService: UserService,
        private modalService: NgbModal,
        private mascaras: Mascaras,
        public keycloakService: KeycloakService,
        private logonApi: LogonApi,
        private publicoPrivadaApi: PublicoPrivadaApi,
        private dateConverter: DateConverter,
        private relatoriosApi: RelatoriosApi,
        private utils: Utils) {
        this.isCPF.next(false);
        this.isCNPJ.next(false);
        this.isNotSelect.next(true);
        this.servicoPrestadoSelecionado = new ServicoPrestadoPf();
        this.noServicoId.next(true);
    }

    ngOnInit() {
        this.isFiscal = this.userService.isFiscal();
        this.isSMTC = this.userService.isSMTC();
        this.isConsulta = this.userService.isConsulta();
        this.userService.userInfo.subscribe(user => this.username = user.email);
        this.temPermissao.next(false);
        this.naoTemPermissao.next(true);
        this.isBloquearEdicaoEndereco = true;
        this.servicoPrestadoSelecionado = new ServicoPrestadoPf();
        this.servicoPrestadoSelecionado.id = 0;
        this.noServicoId.next(true);
        this.setupInterface();
    }

    setupInterface() {
        this.loading.next(true);
        if (!this.isFiscal && !this.isSMTC && !this.isConsulta && !this.isPortal) {
            this.logonApi.getListaParceriasLogon(this.keycloakService['_keycloakInstance'].tokenParsed.email).subscribe((response) => {
                if (response) {
                    this.temPermissao.next(true);
                    response.forEach(element => {
                        this.cnpjs.push([element[0], element[1]]);
                    });
                    this.naoTemPermissao.next(false);
                }
            }, (response) => {
                if (response.status >= 500) {
                    this.alertService.danger(response.error);
                } else {
                    this.alertService.warning(response.error);
                }
                this.loading.next(false);
                this.temPermissao.next(false);
                this.naoTemPermissao.next(true);
            }, () => this.loading.next(false));

        } else {

            this.getPrestadoresPorTermo();
            this.keycloakService.getUser().subscribe((user: AuthUser) => {
                this.logonApi.getListaParceriasOrgao(Number(user.attributes.sgp_orgao[0])).subscribe((response) => {
                    if (response) {
                        this.temPermissao.next(true);
                        response.forEach(element => {
                            this.cnpjs.push([element[0], element[1]]);
                        });
                        this.naoTemPermissao.next(false);
                    }
                }, (response) => {
                    if (response.status >= 500) {
                        this.alertService.danger(response.error);
                    } else {
                        this.alertService.warning(response.error);
                    }
                    this.temPermissao.next(false);
                    this.loading.next(false);
                    this.naoTemPermissao.next(true);
                }, () => this.loading.next(false));
            });
        }
        this.getOcupacaoLaboralLista();
        this.noServicoId.next(true);
    }

    aplicaMascaraCNPJ(cnpj) {
        return this.mascaras.aplicaMascaraDocumento(cnpj, 2);
    }

    aplicaMascaraCpfCnpj(prestador) {
        if (prestador.isCPF) {
            return this.mascaras.aplicaMascaraDocumento(prestador.empresaCnpjCpf, 1);
        }
        else {
            return this.mascaras.aplicaMascaraDocumento(prestador.empresaCnpjCpf, 2);
        }
    }

    setCnpj(event) {
        const newVal = event.currentTarget.value;
        this.cnpjOSC = newVal;
        this.cnpjs.forEach(element => {
            if (element[0] == newVal) {
                this.nomeOsc = element[1].valueOf();
            }
        });
        this.getPrestadores(newVal);
        this.getIsSaude(newVal);
    }

    getPrestadoresPorTermo() {
        this.loading.next(true);
        this.temPermissao.next(true);
        this.detalheParceriaApi.getPrestadoresServTerPorTermo(this.parceriaInfo.parId).subscribe((response) => {
            if (response) {
                this.prestadores = response as Array<PrestadorServTer>;
            } else {
                this.alertService.warning('Nenhum registro encontrado');
            }
        }, (response) => {
            if (response.status >= 500) {
                this.alertService.danger(response.error);
            } else {
                this.alertService.warning(response.error);
            }
            this.loading.next(false);
        }, () => this.loading.next(false));
    }

    getPrestadores(cnpjOSC) {
        this.loading.next(true);
        this.detalheParceriaApi.getPrestadoresServTerPorOsc(cnpjOSC, false, 0).subscribe((response) => {
            if (response) {
                this.prestadores = response as Array<PrestadorServTer>;
            } else {
                this.alertService.warning('Nenhum registro encontrado');
            }
            this.loading.next(false);
        }, (response) => {
            if (response.status >= 500) {
                this.alertService.danger(response.error);
            } else {
                this.alertService.warning(response.error);
            }
            this.loading.next(false);
        }, () => this.loading.next(false));
    }

    getPrestadorPFeServTer() {
        this.loading.next(true);
        this.detalheParceriaApi.getPrestadorPFeServTer(this.prestadorSend.id, this.servicoPrestadoSelecionado.id).subscribe((response) => {
            if (response) {
                let prestador = response as PrestadorServTer;
                this.servicoPrestadoSelecionado = prestador.servicosPrestadosPf[0];
            } else {
                this.alertService.warning('Nenhum registro encontrado');
            }
            this.loading.next(false);
        }, (response) => {
            if (response.status >= 500) {
                this.alertService.danger(response.error);
            } else {
                this.alertService.warning(response.error);
            }
            this.loading.next(false);
        }, () => this.loading.next(false));
    }

    disableCampos() {
        return (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta);
    }

    visualizarBotao() {
        return (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta);
    }

    disableApagarIcone() {
        return (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta);
    }

    enableEditarIcone() {
        if (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta) {
            return false;
        }
        return (this.cnpjOSC != undefined);
    }

    updatePessoa(event) {
        const newVal = event.currentTarget.value;
        if (newVal == 'F') {
            this.isCPF.next(true);
            this.isCNPJ.next(false);
            this.isNotSelect.next(false);
            if (this.prestadorSend != undefined) {
                this.prestadorSend.isCPF = true;
            }
        }
        if (newVal == 'J') {
            this.isCNPJ.next(true);
            this.isCPF.next(false);
            this.isNotSelect.next(false);
            if (this.prestadorSend != undefined) {
                this.prestadorSend.isCPF = false;
            }
        }
        this.selectOscCnpj(2);

    }

    isNotSelectedOrNotPjPf(tipo) {
        let subject = new BehaviorSubject<boolean>(false);
        if (this.isCNPJ.value == false && this.isCPF.value == false) {
            subject.next(true);
        }
        else {
            if (this.isCPF.value == true && tipo == 'PF') {
                subject.next(true);
            }
            else if (this.isCNPJ.value == true && tipo == 'PJ') {
                subject.next(true);
            }
            else {
                subject.next(false);
            }
        }
        return subject.asObservable();
    }

    isNotSelectedOrNotPF() {
        let subject = new BehaviorSubject<boolean>(false);
        if (this.isCNPJ.value == false && this.isCPF.value == false) {
            subject.next(true);
        }
        else {
            if (this.isCNPJ.value == true) {
                subject.next(true);
            }
            else {
                subject.next(false);
            }
        }
        return subject.asObservable();
    }


    setPrestadorServTer(doc) {
        this.servicoPrestadoSelecionado = new ServicoPrestadoPf();
        this.servicoPrestadoSelecionado.id = 0;
        this.logonApi.getParceriasByCnpj(this.cnpjOSC === undefined ? this.parceriaInfo.parOrgCnpj : this.cnpjOSC).subscribe((response) => {
            this.termos = [];
            response.forEach(element => {
                this.termos.push([element[0], element[3].trim()]);
                //console.log("parid: " + element[0]);
            });
        })

        if (doc) {
            const datePipe = new DatePipe('en-US');
            this.isEdicao = true;
            this.detalheParceriaApi.getPrestadorServTer(doc.id, doc.isCPF).subscribe((response) => {
                this.prestadorSend = response;
                this.prestadorSend.idsContratosPjAExcluir = [];
                this.prestadorSend.idsServicosPrestadosPfAExcluir = [];
                if (this.prestadorSend.isCPF) {
                    this.isCPF.next(true);
                    this.isCNPJ.next(false);
                    //this.isNotSelectedOrNotPjPf('PF');
                }
                else {
                    this.isCNPJ.next(true);
                    this.isCPF.next(false);
                }

                if (this.prestadorSend.servicosPrestadosPf == null || this.prestadorSend.servicosPrestadosPf == undefined) {
                    this.prestadorSend.servicosPrestadosPf = [];
                } else {
                    this.listaServicoPrestadoOption = [];
                    this.listaServicoPrestadoOption.push({ id: '0', name: '' });
                    const serv = new ServicoPrestadoPf();
                    serv.id = 0;

                    this.prestadorSend.servicosPrestadosPf.forEach((element, index) => {
                        if (element.inicioExecucao != null) {
                            element.inicioExecucaoDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(this.dateConverter.fromDateToTimestamp(element.inicioExecucao));
                        }
                        if (element.fimExecucao != null) {
                            element.fimExecucaoDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(this.dateConverter.fromDateToTimestamp(element.fimExecucao));
                        }
                        element.tipoAtividadeSelecionada = this.utils.tipoAtividade.find(item => item.id == element.tipoAtividade).id;
                        element.ocupacaoLaboralSelecionada = this.ocupacaoLaboralLista.find(item => item.id == element.tipoDeServico);

                        this.listaServicoPrestadoOption.push({
                            id: element.id,
                            name: datePipe.transform(element.inicioExecucao, 'dd/MM/yyyy') + " a " + datePipe.transform(element.fimExecucao, 'dd/MM/yyyy') + " - " + element.descricaoServico
                        });
                    });
                }

                if (this.prestadorSend.contratosPj == null || this.prestadorSend.contratosPj == undefined) {
                    this.prestadorSend.contratosPj = [];
                } else {
                    this.prestadorSend.contratosPj.forEach((element, index) => {
                        if (element.inicioVigenciaContrato != null) {
                            element.inicioVigenciaContratoDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(this.dateConverter.fromDateToTimestamp(element.inicioVigenciaContrato));
                        }
                        if (element.fimVigenciaContrato != null) {
                            element.fimVigenciaContratoDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(this.dateConverter.fromDateToTimestamp(element.fimVigenciaContrato));
                        }
                        if (element.inicioVigenciaValorMensal != null) {
                            element.inicioVigenciaValorMensalDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(this.dateConverter.fromDateToTimestamp(element.inicioVigenciaValorMensal));
                        }
                        if (element.fimVigenciaValorMensal != null) {
                            element.fimVigenciaValorMensalDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(this.dateConverter.fromDateToTimestamp(element.fimVigenciaValorMensal));
                        }
                        element.tipoAtividadeSelecionada = this.utils.tipoAtividade.find(item => item.id == element.tipoAtividade).id;
                        //element.ocupacaoLaboralSelecionada = this.ocupacaoLaboralLista.find(item => item.id == element.tipoDeServico);

                    });
                }
            });
        } else {
            this.isEdicao = false;
            this.isNotSelectedOrNotPjPf('');
            this.prestadorSend = new PrestadorServTer();
            this.prestadorSend.id = -1;
            this.prestadorSend.empresaCnpjOsc = this.cnpjOSC;
            this.prestadorSend.empresaInscricaoMunicipal = null;
            this.prestadorSend.numeroInscricaoRGPS = 0;
            this.prestadorSend.numeroRG = null;
            this.prestadorSend.orgaoEmissor = null;
            this.prestadorSend.numeroRegistroProfissional = 0;
            this.prestadorSend.orgaoFiscalizador = null;
            this.prestadorSend.numeroCodigoBrasileiroOcupacao = 0;
            this.prestadorSend.empresaRazaoSocialOsc = null;
            this.prestadorSend.servicosPrestadosPf = [];
            this.prestadorSend.contratosPj = [];
            this.prestadorSend.empresaAtividadePrincipal = null;
            this.prestadorSend.idsServicosPrestadosPfAExcluir = [];
            this.prestadorSend.idsContratosPjAExcluir = [];
            this.prestadorSend.empresaStatus = null;
            this.showUpload = true;
            this.listaServicoPrestadoOption = [];
            this.listaServicoPrestadoOption.push({ id: '0', name: '' });
            this.isCNPJ.next(false);
            this.isCPF.next(false);
        }
    }

    adicionaNovoServico() {
        const serv = new ServicoPrestadoPf();
        serv.id = -1;
        this.prestadorSend.servicosPrestadosPf.push(serv);
        serv.cnpjOsc = this.cnpjOSC;
        this.servicoPrestadoSelecionado = serv;
        this.selectOscCnpj(2);
 
    }

    updateServicoSelecionado(event) {
        const newVal = event.id;
        let serv =  this.prestadorSend.servicosPrestadosPf.find(serv => serv.id.toString() == newVal);
        if (serv != null){
            this.servicoPrestadoSelecionado = serv;
            this.noServicoId.next(false);
        }
        else{
            this.servicoPrestadoSelecionado = new ServicoPrestadoPf();
            this.servicoPrestadoSelecionado.id=0;
            this.noServicoId.next(true);
        }
    }

    openEdicao(content, doc) {
        this.setPrestadorServTer(doc);
        this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false, windowClass: "modalProcesso" });
    }

    openExcluir(content, doc) {
        this.setPrestadorServTer(doc);
        this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false });
    }

    openExcluirServ(content, doc) {
        this.servicoPrestadoSelecionado = doc;
        this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false });
    }

    selectCpfCnpj(tipoPessoa) {

        this.prestadorSend.empresaEndereco =""; 
        this.prestadorSend.empresaCep="";
        if (this.prestadorSend.empresaCnpjCpf == undefined) {
            this.inValidCpf = true;
        } else {
            this.inValidCpf = !this.mascaras.validaCpfCnpj(this.prestadorSend.empresaCnpjCpf);
            if (!this.inValidCpf) {
                this.loading.next(true);
                this.detalheParceriaApi.getPessoa(this.prestadorSend.empresaCnpjCpf.replace(/[^\d]+/g, ''), tipoPessoa)
                    .subscribe((response) => {
                        if (response) {
                            this.pessoaInfo = response as PessoaInfo;
                            this.prestadorSend.empresaNomeFantasia = this.pessoaInfo.nomeFantasia;
                            this.prestadorSend.empresaStatus = this.pessoaInfo.situacao === 'A' ? 'Ativo' : 'Cancelado';
                            this.prestadorSend.empresaRazaoSocial = this.pessoaInfo.nome;
                            if (this.isBlank(this.pessoaInfo.nomeLogradouro))
                                this.isBloquearEdicaoEndereco = false;
                            else{
                                this.isBloquearEdicaoEndereco = true;
                                const nomeLogradouro = this.pessoaInfo.nomeLogradouro || '';
                                const numeroEndereco = this.pessoaInfo.numeroEndereco || '';
                                const compl = this.isBlank(this.pessoaInfo.compl) ? '' : this.pessoaInfo.compl;
                                const cidade = this.isBlank(this.pessoaInfo.enderecos[0]?.cidade) ? '' : this.pessoaInfo.enderecos[0].cidade;
                                const uf = this.isBlank(this.pessoaInfo.enderecos[0]?.uf) ? '' : this.pessoaInfo.enderecos[0].uf;
                                this.prestadorSend.empresaEndereco  = `${nomeLogradouro}, ${numeroEndereco} ${compl} ${cidade ? `- ${cidade}` : ''} ${uf ? `- ${uf}` : ''}`;

                            }
                            this.prestadorSend.empresaCep = this.pessoaInfo.cep.replace(/[^\d]+/g, '');
                            if (this.pessoaInfo.atividadeCnaeIsqs != null) {
                                this.pessoaInfo.atividadeCnaeIsqs.forEach(element => {
                                    if (element.flgAtividadePrincipal.toUpperCase() === 'S') {
                                        this.prestadorSend.empresaAtividadePrincipal = element.desCnae;
                                    }
                                })
                            }

                            this.prestadorSend.numeroRG = this.pessoaInfo.numeroRg;
                            this.loading.next(false);
                        }
                        else {
                            this.isBloquearEdicaoEndereco = false;
                        }
                    }, (response) => {
                        if (response.status >= 500) {
                            this.alertService.danger(response.error);
                        } else {
                            this.alertService.warning(response.error);
                        }
                        this.loading.next(false);
                    }, () => this.loading.next(false));
                this.loading.next(false);
            }
        }
    }

    selectOscCnpj(tipoPessoa) {

        if (this.servicoPrestadoSelecionado.cnpjOsc == undefined) {
            this.inValidCpf = true;
        } else {
            this.inValidCpf = !this.mascaras.validaCpfCnpj(this.servicoPrestadoSelecionado.cnpjOsc);
            if (!this.inValidCpf) {
                this.loading.next(true);
                this.detalheParceriaApi.getPessoa(this.servicoPrestadoSelecionado.cnpjOsc.replace(/[^\d]+/g, ''), tipoPessoa)
                    .subscribe((response) => {
                        if (response) {
                            this.pessoaInfo = response as PessoaInfo;
                            this.servicoPrestadoSelecionado.razaoSocialOsc = this.pessoaInfo.nome;
                            if (this.isBlank(this.pessoaInfo.nomeLogradouro))
                                this.isBloquearEdicaoEndereco = false;
                            else{
                                this.isBloquearEdicaoEndereco = true;
                                const nomeLogradouro = this.pessoaInfo.nomeLogradouro || '';
                                const numeroEndereco = this.pessoaInfo.numeroEndereco || '';
                                const compl = this.isBlank(this.pessoaInfo.compl) ? '' : this.pessoaInfo.compl;
                                const cidade = this.isBlank(this.pessoaInfo.enderecos[0]?.cidade) ? '' : this.pessoaInfo.enderecos[0].cidade;
                                const uf = this.isBlank(this.pessoaInfo.enderecos[0].uf) ? '' : this.pessoaInfo.enderecos[0].uf;
                                this.servicoPrestadoSelecionado.enderecoOsc  = `${nomeLogradouro}, ${numeroEndereco} ${compl} ${cidade ? `- ${cidade}` : ''} ${uf ? `- ${uf}` : ''}`;
                                const cep = this.isBlank(this.pessoaInfo.enderecos[0].cep) ? '' : this.pessoaInfo.enderecos[0].cep;
                                this.servicoPrestadoSelecionado.cepOsc = `${cep}`;
                            }
                            this.loading.next(false);
                        }
                    }, (response) => {
                        if (response.status >= 500) {
                            this.alertService.danger(response.error);
                        } else {
                            this.alertService.warning(response.error);
                        }
                        this.loading.next(false);
                    }, () => this.loading.next(false));
                this.loading.next(false);
            }
        }
    }

    downloadPrestadorServTerDocumento(id, contratoId, nome, tipoArquivo) {
        this.loading.next(true);
        this.publicoPrivadaApi.getArquivoPrestadorServTer(id, contratoId, tipoArquivo, this.isPortal).subscribe((response) => {
            if (response) {
                console.log(response);
                const a = document.createElement('a');
                const url = window.URL.createObjectURL(response.body);
                a.href = url;
                a.download = nome;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            } else {
                this.alertService.warning('Nenhum arquivo encontrado');
            }
        }, (response) => {
            // if (response.status >= 500) {
            //     this.alertService.danger(response.error);
            // } else {
            //     this.alertService.warning(response.error);
            // }
            this.alertService.danger('Erro ao buscar arquivo de colaborador.');
            this.loading.next(false);
        }, () => this.loading.next(false));
    }

    updateTextArea(valor) {
        this.prestadorSend.empresaEndereco = valor;
    }

    updateTextAreaLocalAtuacao(valor, cargaHoraria) {
        cargaHoraria.localAtuacao = valor;
    }

    replacerPrestador(key, value) {
        //atributos que só existem no view e não no servidor
        const ignoreList = ['ocupacaoLaboralSelecionada', 'tipoAtividadeSelecionada', 'inicioExecucaoDataFormat', 'fimExecucaoDataFormat', 'dataInicioParceriaDataFormat',
            'dataFimParceriaDataFormat', 'inicioVigenciaContratoDataFormat', 'fimVigenciaContratoDataFormat', 'inicioVigenciaValorMensalDataFormat', 'fimVigenciaValorMensalDataFormat'];
        if (ignoreList.indexOf(key) > -1) {
            return undefined;
        } else {
            return value;
        }
    }

    isBlank(str) {
        return (!str || /^\s*$/.test(str));
    }

    validaDataInicioFim(data1: Date, data2: Date): boolean {
        return data2 < data1;
    }


    salvaPrestadorPF() {

        //validar valores dos campos numericos qdo é pf?
        if (this.isBlank(this.servicoPrestadoSelecionado.razaoSocialOsc)
            || this.isBlank(this.servicoPrestadoSelecionado.cepOsc) || this.prestadorSend.empresaCep == undefined
            || this.isBlank(this.servicoPrestadoSelecionado.enderecoOsc)
            || (this.servicoPrestadoSelecionado.comprovanteRGPS == undefined || this.servicoPrestadoSelecionado.comprovanteRGPS == null)
            || this.isBlank(this.servicoPrestadoSelecionado.descricaoServico)) {
            this.alertService.danger('Verifique o preenchimento de todos os campos obrigatórios. Não foi possível salvar os dados.');
            this.loading.next(false);
            return false;
        }

        if (this.inValidCpf) {
            this.alertService.danger('CPF/CNPJ inválido.');
            this.loading.next(false);
            return false;
        }

        this.servicoPrestadoSelecionado.operacaoData = new Date();
        this.servicoPrestadoSelecionado.usuarioOperacao = this.username;

        if (this.servicoPrestadoSelecionado.inicioExecucaoDataFormat === null) {
            this.alertService.danger('Por favor, informe a data de início da execução. Não foi possível salvar os dados.');
            return false;
        }
        else {
            this.servicoPrestadoSelecionado.inicioExecucao = this.dateConverter.fromNgbDateStructToDate(this.servicoPrestadoSelecionado.inicioExecucaoDataFormat);
        }
        if (!this.utils.isValidDate(this.servicoPrestadoSelecionado.inicioExecucao)) {
            this.alertService.danger('Data de Início Execução Inválida.');
            return false;
        }

        if (this.servicoPrestadoSelecionado.fimExecucaoDataFormat === null) {
            this.alertService.danger('Por favor, informe a data final da execução. Não foi possível salvar os dados.');
            return false;
        }
        else {
            this.servicoPrestadoSelecionado.fimExecucao = this.dateConverter.fromNgbDateStructToDate(this.servicoPrestadoSelecionado.fimExecucaoDataFormat);

        }

        if (!this.utils.isValidDate(this.servicoPrestadoSelecionado.fimExecucao)
            || this.validaDataInicioFim(this.servicoPrestadoSelecionado.inicioExecucao, this.servicoPrestadoSelecionado.fimExecucao)) {
            this.alertService.danger('Data Fim Execução Inválida.');
            return false;
        }
        if (this.servicoPrestadoSelecionado.tipoAtividade === null || this.servicoPrestadoSelecionado.tipoAtividade === 0) {
            this.alertService.danger('Por favor, informe o tipo de atividade. Não foi possível salvar os dados.');
            return false;
        }
        this.servicoPrestadoSelecionado.cnpjOsc = this.servicoPrestadoSelecionado.cnpjOsc.replace(/[^\d]+/g, '');
        return true;
    }

    salvaPrestadorPJ(){
        let erroFindSP: boolean = false;
        if (this.prestadorSend.contratosPj.length < 1) {
            this.alertService.danger('É necessário informar o contrato.');
            this.loading.next(false);
            erroFindSP = true;
            return false;
        }
        this.prestadorSend.contratosPj.forEach(element => {
            if (element.inicioVigenciaContratoDataFormat !== undefined &&
                element.inicioVigenciaContratoDataFormat != null) {
                element.inicioVigenciaContrato = this.dateConverter.fromNgbDateStructToDate(element.inicioVigenciaContratoDataFormat);
                if (!this.utils.isValidDate(element.inicioVigenciaContrato)) {
                    this.alertService.danger('Data Início Contrato Inválida.');
                    this.loading.next(false);
                    erroFindSP = true;
                    return false;
                }
            } else {
                element.inicioVigenciaContrato = null;
                this.alertService.danger('Por favor, informe a data de início do contrato. Não foi possível salvar os dados.');
                this.loading.next(false);
                erroFindSP = true;
                return false;
            }

            if (element.fimVigenciaContratoDataFormat !== undefined &&
                element.fimVigenciaContratoDataFormat != null) {
                element.fimVigenciaContrato = this.dateConverter.fromNgbDateStructToDate(element.fimVigenciaContratoDataFormat);
                if (!this.utils.isValidDate(element.fimVigenciaContrato)
                    || this.validaDataInicioFim(element.inicioVigenciaContrato, element.fimVigenciaContrato)) {
                    this.alertService.danger('Data Fim Contrato Inválida.');
                    this.loading.next(false);
                    erroFindSP = true;
                    return false;
                }
            } else {
                element.fimVigenciaContrato = null;
            }

            if (element.inicioVigenciaValorMensalDataFormat !== undefined &&
                element.inicioVigenciaValorMensalDataFormat != null) {
                element.inicioVigenciaValorMensal = this.dateConverter.fromNgbDateStructToDate(element.inicioVigenciaValorMensalDataFormat);
                if (!this.utils.isValidDate(element.inicioVigenciaValorMensal)) {
                    this.alertService.danger('Data Início Valor Mensal Inválida.');
                    this.loading.next(false);
                    erroFindSP = true;
                    return false;
                }
            } else {
                element.inicioVigenciaValorMensal = null;
                this.alertService.danger('Por favor, informe a data de início do Valor Mensal. Não foi possível salvar os dados.');
                this.loading.next(false);
                erroFindSP = true;
                return;
            }

            if (element.fimVigenciaValorMensalDataFormat !== undefined &&
                element.fimVigenciaValorMensalDataFormat != null) {
                element.fimVigenciaValorMensal = this.dateConverter.fromNgbDateStructToDate(element.fimVigenciaValorMensalDataFormat);
                if (!this.utils.isValidDate(element.fimVigenciaValorMensal)
                    || this.validaDataInicioFim(element.inicioVigenciaValorMensal, element.fimVigenciaValorMensal)) {
                    this.alertService.danger('Data Fim Valor Mensal Inválida.');
                    this.loading.next(false);
                    erroFindSP = true;
                    return;
                }
            } else {
                element.fimVigenciaValorMensal = null;
            }

            if (element.tipoDeServico === null) {
                this.alertService.danger('Por favor, informe o tipo de serviço. Não foi possível salvar os dados.');
                this.loading.next(false);
                erroFindSP = true;
                return;
            }
            if (element.valorMensalVigente === 0 || element.tipoDeServico === undefined) {
                this.alertService.danger('Por favor, informe o valor mensal vigente. Não foi possível salvar os dados.');
                this.loading.next(false);
                erroFindSP = true;
                return;
            }
            if (element.tipoAtividade === null || element.tipoAtividade === 0) {
                this.alertService.danger('Por favor, informe o tipo de atividade. Não foi possível salvar os dados.');
                this.loading.next(false);
                erroFindSP = true;
                return;
            }

            if (this.isBlank(element.contratoNome)) {
                this.alertService.danger('Contrato deve ser anexado.');
                return;
            } else
                if (element.contratoNome.length > 50) {
                    this.alertService.danger('Nome do arquivo superior a 50 caracteres, por favor renomeie antes de fazer upload.');
                    return;
                }

        })
        if (erroFindSP) {
            return false;
        }
        return true;
    }


    salvaPrestador() {
        this.loading.next(true);
        if (this.isBlank(this.prestadorSend.empresaCnpjCpf) || this.isBlank(this.prestadorSend.empresaRazaoSocial)
            || this.isBlank(this.prestadorSend.empresaCep) || this.prestadorSend.empresaCep == undefined
            || this.isBlank(this.prestadorSend.empresaEndereco)) {
            this.alertService.danger('Verifique o preenchimento de todos os campos obrigatórios. Não foi possível salvar os dados.');
            this.loading.next(false);
            return;
        }

        this.prestadorSend.operacaoData = new Date();
        this.prestadorSend.usuarioOperacao = this.username;
        this.prestadorSend.empresaCnpjCpf = this.prestadorSend.empresaCnpjCpf.replace(/[^\d]+/g, '');
        
        if (this.prestadorSend.isCPF && !this.salvaPrestadorPF()){
            return;
        }
        else if(!this.prestadorSend.isCPF && !this.salvaPrestadorPJ()){
            return;
        }

        let data = JSON.stringify(this.prestadorSend, this.replacerPrestador);
        this.detalheParceriaApi.updatePrestadorServTer(data).subscribe(async (response) => {
            if (response) {
                let idPrestador = response.body as unknown as number;
                if (!this.isCPF){
                    this.setupInterface();
                    this.setPrestadorServTer(undefined);
                    this.getPrestadores(this.cnpjOSC);    
                    this.modalEdicaoReference.close();
                }
                else{
                    this.prestadorSend.id = idPrestador;
                    if (this.servicoPrestadoSelecionado.id ==-1){
                        await this.detalheParceriaApi.getIdUltimoServicoIncluido(idPrestador).subscribe(async (response) => {
                            if (response) {
                                this.servicoPrestadoSelecionado.id = response as unknown as number;
                                this.noServicoId.next(false);
                            }
                        }), (response) => {
                            if (response.status >= 500) {
                                this.alertService.danger(response.error);
                            } else {
                                this.alertService.warning(response.error);
                            }
                        }
                    }
                }
                
                this.alertService.info("Cadastro inserido/atualizado com sucesso!")
            }
        }, (response) => {
            if (response.status >= 500) {
                this.alertService.danger(response.error);
            } else {
                this.alertService.warning(response.error);
            }
            this.loading.next(false);
            this.modalEdicaoReference.close();
        }, () => this.loading.next(false));
    }

    updateTipoAtividade(event, data) {
        const value = event.currentTarget.value;
        data.tipoAtividade = value;
    }

    getIsSaude(cnpj: string) {
        this.detalheParceriaPortalApi.getIsSaude(cnpj).subscribe(async (response) => {
            this.isSaude = response as boolean;
        }, (response) => {
            if (response.status >= 500) {
                this.alertService.danger(response.error);
            } else {
                this.alertService.warning(response.error);
            }
            this.loading.next(false);
        }, () => this.loading.next(false));
    }


    verificaServicosPrestados(): boolean {
        if (this.prestadorSend.servicosPrestadosPf != null &&
            this.prestadorSend.servicosPrestadosPf.length > 0) {
            if (this.prestadorSend.servicosPrestadosPf[0].tipoDeServico != null
                && this.prestadorSend.servicosPrestadosPf[0].inicioExecucaoDataFormat != null
                && this.prestadorSend.servicosPrestadosPf[0].descricaoServico != null) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    verificaContratosPJ(): boolean {
        if (this.prestadorSend.contratosPj != null &&
            this.prestadorSend.contratosPj.length > 0) {
            if (this.prestadorSend.contratosPj[0].tipoDeServico != null
                && this.prestadorSend.contratosPj[0].inicioVigenciaContratoDataFormat != null
                && this.prestadorSend.contratosPj[0].inicioVigenciaValorMensalDataFormat != null
                && this.prestadorSend.contratosPj[0].valorMensalVigente != 0) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    adicionarServicoPrestado() {
        var servicoPrestadoPf = new ServicoPrestadoPf();
        servicoPrestadoPf.id = -1;
        servicoPrestadoPf.prestadorPFTerceiroId = this.prestadorSend.id;
        servicoPrestadoPf.inicioExecucao = null;
        servicoPrestadoPf.fimExecucao = null;
        servicoPrestadoPf.descricaoServico = null;
        servicoPrestadoPf.tipoDeServico = 0;
        servicoPrestadoPf.tipoAtividade = 0;
        this.prestadorSend.servicosPrestadosPf.push(servicoPrestadoPf);
        this.noServicoId.next(true);
    }

    adicionarContratoPJ() {
        var contratosPj = new ContratosPj();
        contratosPj.id = -1;
        contratosPj.prestadorPJTerceiroId = this.prestadorSend.id;
        contratosPj.inicioVigenciaContrato = null;
        contratosPj.fimVigenciaContrato = null;
        contratosPj.inicioVigenciaValorMensal = null;
        contratosPj.fimVigenciaValorMensal = null;
        contratosPj.valorMensalVigente = 0;
        contratosPj.tipoDeServico = null;
        contratosPj.tipoAtividade = 0;
        this.prestadorSend.contratosPj.push(contratosPj);
    }

    excluirServicoPrestado(servicoPrestadoPf) {
        const index: number = this.prestadorSend.servicosPrestadosPf.indexOf(servicoPrestadoPf);
        if (index !== -1) {
              this.detalheParceriaApi.excluirServPrestadoTer(servicoPrestadoPf.id).subscribe((response) => {
                if (response) {
                    this.prestadorSend.servicosPrestadosPf.splice(index, 1);
                    this.listaServicoPrestadoOption.splice(index+1,1);//deslocado pq o primeiro é vazio
                    this.servicoPrestadoSelecionado.id=0;
                    this.modalEdicaoReference.close();
                }
            }, (response) => {
                if (response.status >= 500) {
                    this.alertService.danger(response.error);
                } else {
                    this.alertService.warning(response.error);
                }
                this.loading.next(false);
            }, () => this.loading.next(false));    
        }        

    }

    excluirContratoPJ(contratoPj) {
        const index: number = this.prestadorSend.contratosPj.indexOf(contratoPj);
        if (index !== -1) {
            this.prestadorSend.contratosPj.splice(index, 1);
            if (contratoPj.id != -1) {
                this.prestadorSend.idsContratosPjAExcluir.push(contratoPj.id);
            }
        }
    }

    excluirPrestador() {
        this.detalheParceriaApi.excluirPrestadorServTer(this.prestadorSend).subscribe((response) => {
            if (response) {
                this.setupInterface();
                this.setPrestadorServTer(undefined);
                this.modalEdicaoReference.close();
                this.getPrestadores(this.cnpjOSC);
            }
        }, (response) => {
            if (response.status >= 500) {
                this.alertService.danger(response.error);
            } else {
                this.alertService.warning(response.error);
            }
            this.loading.next(false);
        }, () => this.loading.next(false));
    }

    onFileChanged(event, data, tipoArq) {
        const fileList: FileList = event.currentTarget.files;
        const reader = new FileReader();
        if (fileList.length > 0) {
            const array = fileList[0].type.split('/');
            const fileSize = fileList[0].size / 1024 / 1024; // in MB
            if (fileSize > 10) {
                this.alertService.danger('Tamanho de arquivo superior ao limite de 10 MB. Esse arquivo possui ' + fileSize.toPrecision(2) + " MB");
                return;
            }
            if (array[1] === 'pdf' || array[1] === 'gif' || array[1] === 'jpg' || array[1] === 'jpeg' || array[1] === 'png') {
                reader.readAsDataURL(fileList[0]);
                reader.onload = () => {
                    if (tipoArq == 0) {
                        data.contratoArquivo = ((reader.result).toString().split(',')[1]) as unknown as Blob;
                        data.contratoTipo = array[1];
                        data.contratoNome = fileList[0].name;
                    }
                    else if (tipoArq == 1) {
                        data.orcamento1Arquivo = ((reader.result).toString().split(',')[1]) as unknown as Blob;
                        data.orcamento1Tipo = array[1];
                        data.orcamento1Nome = fileList[0].name;
                    }
                    else if (tipoArq == 2) {
                        data.orcamento2Arquivo = ((reader.result).toString().split(',')[1]) as unknown as Blob;
                        data.orcamento2Tipo = array[1];
                        data.orcamento2Nome = fileList[0].name;
                    }
                    else if (tipoArq == 3) {
                        data.orcamento3Arquivo = ((reader.result).toString().split(',')[1]) as unknown as Blob;
                        data.orcamento3Tipo = array[1];
                        data.orcamento3Nome = fileList[0].name;
                    }
                };
            } else {
                event.currentTarget.value = '';
                this.alertService.warning('Formato de arquivo não suportado!');
            }
        }
    }

    relatorioprestadores(tipo) {
        this.loading.next(true);
        this.keycloakService.getUser().subscribe((user: AuthUser) => {
            var busca: BuscaRelatorioCampos = new BuscaRelatorioCampos(Number(user.attributes.sgp_orgao[0]), null, null, '', this.nomeOsc, this.cnpjOSC, 0, 0, 0, '', tipo, 'prestadores', 0, 25, null, null, null);
            this.relatoriosApi.getArquivoRelatorio(busca)
                .subscribe((response) => {
                    if (response) {
                        console.log(response);
                        const a = document.createElement('a');
                        const url = window.URL.createObjectURL(response);
                        const nomeOrgArq = busca.nomeOrg.replace('/', '-');
                        a.download = `relatorio_${busca.nomeRelatorio}_${nomeOrgArq}.${busca.tipoArquivo}`;
                        a.href = url;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);

                    } else {
                        this.alertService.warning('Nenhum resultado foi obtido, por favor refaça a busca com outros critérios.');
                    }
                }, (response) => {
                    if (response.status >= 500) {
                        this.alertService.danger(response.error);
                    } else {
                        this.alertService.warning(response.error);
                    }
                    this.loading.next(false);
                }, () => this.loading.next(false));
        });
    }

    reciboPagamento(prestadorId, servicoId) {
        this.loading.next(true);
        this.keycloakService.getUser().subscribe((user: AuthUser) => {
            this.detalheParceriaApi.getReciboPagtoPrestadorServTer(prestadorId.id, servicoId)
                .subscribe((response) => {
                    if (response) {
                        console.log(response);
                        const a = document.createElement('a');
                        const url = window.URL.createObjectURL(response.body);
                        a.href = url;
                        a.download = `recibo_pagto_${prestadorId.empresaRazaoSocial}.pdf`;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);

                    } else {
                        this.alertService.warning('Nenhum resultado foi obtido, por favor refaça a busca com outros critérios.');
                    }
                }, (response) => {
                    if (response.status >= 500) {
                        this.alertService.danger(response.error);
                    } else {
                        this.alertService.warning(response.error);
                    }
                    this.loading.next(false);
                }, () => this.loading.next(false));
        });
    }
    async getOcupacaoLaboralLista() {
        this.loading.next(true);
        this.detalheParceriaPortalApi.getOcupacaoLaboralAll()
            .subscribe((response) => {
                if (response) {
                    this.ocupacaoLaboralLista = response as Array<OcupacaoLaboral>;
                    // console.log(this.ocupacaoLaboralLista);
                }
            }, (response) => {
                if (response.status >= 500) {
                    this.alertService.danger(response.error);
                } else {
                    this.alertService.warning(response.error);
                }
                this.loading.next(false);
            }, () => this.loading.next(false));
    }
    updateOcupacaoLaboral(event, data, tipo) {
        const value = event.id;
        data.tipoDeServico = value;
    }

    updateOcupacaoLaboralPJ(value, data) {
        data.tipoDeServico = value;
    }

    updateTextAreaDescricaoServico(data, value) {
        data.descricaoServico = value;
    }

    compareFn(item, selected) {
        //  console.log("ITEM", item);
        //   console.log("SELECTED", selected);
        return item.id === selected.id;
    }
}